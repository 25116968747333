export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
  "tournaments": {
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tournois"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos précédents tournois"])},
    "editions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les éditions"])},
    "Alpine_Rivals": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpine Rivals"])},
      "1": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpine Rivals #1"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29 Juin 2024"])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plongez au coeur de l'action avec le premier chapitre d'Alpes Esport. Au programme, une atmosphère électrique avec des voitures sur vitaminées. Ne manquez pas notre premier tournoi en ligne sur Rocket League, le samedi 29 juin 2024. Tournoi en format Swiss round, en 3vs3 et un cashprize de 200€ !"])}
      },
      "2": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpine Rivals #2"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21 Septembre 2024"])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpes Esport revient avec une seconde édition d'Alpine Rivals sur un jeu encore jamais vu dans nos tournois. Cette fois-ci, il faudra planter le SPIKE ! Ne manquez pas notre nouvelle compétition en ligne sur Valorant, le samedi 21 septembre 2024, avec 16 équipes de 5 joueurs pour un cashprize total de 300€ !"])}
      },
      "3": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpine Rivals #3"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09 - 10 Novembre 2024"])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plongez au coeur de l'action avec le troisième chapitre d'Alpes Esport. Au programme, une atmosphère électrique au sein de la faille de l'invocateur. Ne manquez pas notre premier tournoi en ligne sur League Of Legends, le samedi 9 et le dimanche 10 novembre 2024 (check-in à 12 heures 30 le samedi et le dimanche. Pour l'heure de fin approximative, consultez le règlement). Tournoi en format Swiss round, en 5vs5 et un cashprize de 300€ !"])}
      }
    },
    "CEC": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest"])},
      "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant de fonder Chartreuse Gaming, ses membres fondateurs se réunissaient régulièrement pour des Lan party. Au fil du temps, l’envie de jouer se métamorphosa en l’envie d’organiser pour les autres. Ainsi, le tournoi Chartreuse Esport Contest vit le jour en octobre 2016. Depuis, cette première édition aux ambitions modestes et principalement destinée aux locaux, l’association décida de redoubler d’efforts pour mettre sur la carte de France, l’Isère et son engagement pour l’essor de l’eSport."])},
      "1": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 1"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28 Octobre 2017 "])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’association organisa son premier événement sur League Of Legends en 5vs5, le 28 octobre 2017, avec une ambition plutôt modeste. 5 équipes répondirent présentes et les meilleures d’entre-elles se partagèrent le cashprize de 150€."])}
      },
      "2": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 2"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 Octobre 2018"])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’association renouvela son premier événement et en fit son événement phare. Cette seconde édition prit place le 20 octobre 2018. Cette édition accueillit 8 équipes, sur League Of Legends en 5vs5, pour un cashprize global de 400€."])}
      },
      "3": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 3"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26 - 27 Octobre 2019"])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans sa lancée, l’association décida de diversifier son événement en proposant du League Of Legends et du Rocket League lors de la troisième édition des 26 et 27 octobre 2019. League Of Legends connut un événement de taille avec 16 féroces équipes, présentes pour se partager un cashprize de 1000€ tandis que Rocket League connut des débuts encourageants avec 11 équipes, venues des quatre coins de l’hexagone pour un cashprize de 400€."])}
      },
      "4": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 4 Online"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25 Octobre 2020"])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En raison de la crise sanitaire, des mesures réglementaires et légales, l’association n’eut d’autre choix que de dématérialiser sa quatrième édition en privilégiant Rocket League au profit de Counter Strike : Global Offensive et de League Of Legends. Cette édition fut intégralement diffusée par Rocket Baguette et compta 32 slots pour un cashprize de 450€."])}
      },
      "5": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 5"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 Octobre 2021"])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après une année charnière et une situation en demi-teinte, l’association prit le pari de renouveler son édition sous le format de LAN en proposant pour la première fois trois des jeux les plus joués sur la scène compétitive : Counter Strike : Global Offensive, League Of Legends et Rocket League. 98 joueurs répondirent présents sur les trois jeux pour se partager un cashprize taille XXL, de 3500€ !"])}
      },
      "6": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 6"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 et 29 Octobre 2022"])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour un retour flamboyant, l’association vous propose un événement en deux phases sur trois jeux : League Of Legends, Rocket League et Valorant. La première phase, qualificative, se déroule en ligne le samedi 15 octobre 2022. Quant à la seconde phase, finale, elle se tiendra dans les locaux de la Gaming House « Grenoble Esport ». Un généreux cashprize de plus de 1500,00€ sera à se partager entre les derniers combattants de l’arène."])}
      }
    },
    "CGW_2019": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Gaming Winter 2019"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26 Janvier 2019"])},
      "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durant un hiver trop long au goût de certains, l’association proposa un tournoi 2vs2 sur Counter Strike : Global Offensive le temps d’une soirée le 26 janvier 2019 avec une dotation finale de 200€."])}
    }
  },
  "association": {
    "self": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Association"]), _normalize(["L'association"])])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo de l'association"])},
    "presentation": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue dans l’univers d’Alpes Esport, une association loi 1901 fondée par une équipe de passionnés, unie par une vision commune : façonner l’avenir de l’Esport français."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fort d’une expérience passée sous les couleurs de l’association Chartreuse Gaming depuis 2017 avec le Chartreuse Gaming Contest #1, ses adhérents peuvent témoigner de pas moins de 11 tournois physiques et en ligne en l’espace de 7 années, de 450 participants et d’un cashprize excédant les 6500€ / 7500€."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depuis 2015, les adhérents s’efforcent de prendre part à l’essor de l’eSport et de s’inscrire comme des acteurs locaux incontournables dans les Alpes. Afin d’y parvenir, l’association a pour dessein d’organiser plusieurs événements par an dans la métropole grenobloise."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propulsée par un vivier de compétences exceptionnelles et (avec la conviction que l’Esport a un plus grand rôle à jouer)/(croyant fermement en la puissance transformative de l'Esport), l’association souhaite marquer le monde de l’Esport d’une empreinte durable."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainsi, plongez dans l’univers en constante évolution d’Alpes Esport, une association pionnière. Poursuivons nos efforts, dynamisons notre fabuleuse région et tâchons de faire rayonner la France sur la scène internationale."])}
    },
    "numbers": {
      "tournament": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tournois organisés"])},
      "since-2017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["depuis 2017"])},
      "up-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jusqu'à"])},
      "more-than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de"])},
      "players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["joueurs réunis"])},
      "cashprize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de cashprize"])}
    },
    "members": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les membres de l'association"])},
      "head-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres du siège"])},
      "president": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président"])},
      "vice-president": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vice-Président"])},
      "secretary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secrétaire"])}
    },
    "timeline": {
      "2024": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpine Rivals #1"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 29 Juin 2024 en ligne sur Rocket League."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpine Rivals #2"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 21 Septembre 2024 en ligne sur Valorant."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpine Rivals #3"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 09 et 10 Novembre 2024 en ligne sur League Of Legends."])}
        }
      },
      "2023": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement de nom"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'association Chartreuse Gaming change de nom pour devenir Alpes Esport."])}
        }
      },
      "2022": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement du siège social"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 22 mai 2022 vers Saint-Martin d’Hères par décision de l’assemblée générale extraordinaire."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tournoi FIFA SOS Jeunes pousses"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 4 juillet 2022 à la maison de quartier Paul-Bert."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 6"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 15 Octobre 2022 en ligne et le 29 Octobre 2022 Octobre en phsique dans les locaux de Grenoble Esport."])}
        }
      },
      "2021": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus Games Week"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du 22 au 27 février 2021 sur le Discord de l’association."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus Games Week: Crous Edition"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du 17 mai au 19 mai 2021 sur le Discord de l’association."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 5"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 24 octobre 2021 à la salle du Révol de Saint Laurent du Pont."])}
        }
      },
      "2020": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salon du Jeu Vidéo du PAJ"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 1er février 2020 à la salle du Révol de Saint Laurent du Pont."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 4 Online"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 25 octobre 2020 sur le Discord de l’association."])}
        }
      },
      "2019": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Gaming Winter"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 26 janvier 2019 sur le Discord de l’association."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salon du Jeu Vidéo du PAJ"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 2 février 2019 à la salle des fêtes de Saint Joseph de Rivière."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 3"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les 26 et 27 octobre 2019 à la salle du Révol de Saint Laurent du Pont."])}
        }
      },
      "2018": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 2"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 20 octobre 2018 à la salle des fêtes de Saint Joseph de Rivière."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salon du Jeu Vidéo du PAJ"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 20 janvier 2018 à la salle de la Priola à Miribel-les-Echelles."])}
        }
      },
      "2017": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 1"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 28 octobre 2017 à la maison des associations à Saint Laurent du Pont."])}
        }
      },
      "2015": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de l’association"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 29 décembre 2015 à la maison des associations à Saint Laurent du Pont."])}
        }
      }
    }
  },
  "contact": {
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous contacter"])},
    "join-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez-nous !"])}
  },
  "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous droits réservés"])},
  "site-map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan du site"])},
  "legal-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions légales"])},
  "intranet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intranet"])},
  "load-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger plus"])},
  "read-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
  "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeux"])},
  "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
  "regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règlement"])},
  "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présentation"])},
  "ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classement"])},
  "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerie"])},
  "cashprize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashprize"])}
}