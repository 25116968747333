export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "tournaments": {
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tournaments"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our previous tournaments"])},
    "editions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The editions"])},
    "Alpine_Rivals": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpine Rivals"])},
      "1": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpine Rivals #1"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29th June 2024 "])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dive into the heart of the action with the first chapter of Alpes Esport. The program features an electric atmosphere and over-vitaminized cars. Don't miss our first Rocket League online tournament, on Saturday June 29, 2024. Swiss round format, 3vs3 and a €200 cashprize!"])}
      },
      "2": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpine Rivals #2"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21 Septembre 2024"])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpes Esport is back with a second edition of Alpine Rivals, featuring a game never before seen in our tournaments. This time, you'll have to plant the SPIKE! Don't miss our new online competition on Valorant, on Saturday September 21, 2024, with 16 teams of 5 players for a total cashprize of €300!"])}
      },
      "3": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpine Rivals #3"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09 - 10 November 2024"])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dive into the heart of the action with the third chapter of Alpes Esport. The program features an electric atmosphere in the Summoner's Rift. Don't miss our first League Of Legends online tournament, on Saturday November 9 and Sunday November 10, 2024 (check-in at 12.30 p.m. on Saturday and Sunday. For approximate end time, see the rules). Swiss round format, 5vs5 and a €300 cashprize!"])}
      }
    },
    "CEC": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest"])},
      "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before founding Chartreuse Gaming, its founding members regularly got together for Lan parties. Over time, the desire to play turned into the desire to organize for others. Thus, the Chartreuse Esport Contest tournament was born in October 2016. Since this first edition with modest ambitions and mainly intended for the locals, the association decided to redouble its efforts to put on the map of France, Isère and its commitment to the development of eSport.\n\nTranslated with www.DeepL.com/Translator (free version)"])},
      "1": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 1"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28th October 2017 "])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The association organized its first League Of Legends event in 5vs5 on October 28, 2017, with a rather modest ambition. 5 teams responded and the best of them shared the €150 cashprize."])}
      },
      "2": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 2"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20th October 2018"])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The association renewed its first event and made it its flagship event. This second edition took place on October 20, 2018. This edition welcomed 8 teams, on League Of Legends in 5vs5, for a global cashprize of 400€."])}
      },
      "3": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 3"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26 - 27th October 2019"])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In its momentum, the association decided to diversify its event by offering League Of Legends and Rocket League at the third edition on October 26 and 27, 2019. League Of Legends had a great event with 16 fierce teams, present to share a cashprize of 1000€ while Rocket League had an encouraging start with 11 teams, coming from all over France for a cashprize of 400€."])}
      },
      "4": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 4 Online"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25th October 2020"])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to the health crisis, regulatory and legal measures, the association had no choice but to dematerialize its fourth edition by favoring Rocket League over Counter Strike: Global Offensive and League Of Legends. This edition was entirely distributed by Rocket Baguette and had 32 slots for a cashprize of 450€."])}
      },
      "5": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 5"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24th October 2021"])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After a pivotal year and a situation in half-tone, the association took the bet to renew its edition under the format of LAN by proposing for the first time three of the most played games on the competitive scene: Counter Strike: Global Offensive, League Of Legends and Rocket League. 98 players answered present on the three games to share a cashprize size XXL, of 3500€!"])}
      },
      "6": {
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 6"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15th and 29th October 2022"])},
        "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a flamboyant return, the association proposes a two-phase event on three games: League Of Legends, Rocket League and Valorant. The first phase, qualifying, will take place online on Saturday, October 15, 2022. As for the second phase, the final, it will be held in the Gaming House \"Grenoble Esport\". A generous cashprize of more than 1500,00€ will be shared between the last fighters of the arena.\n\nTranslated with www.DeepL.com/Translator (free version)"])}
      }
    },
    "CGW_2019": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Gaming Winter 2019"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26th January 2019"])},
      "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During a winter too long for the taste of some, the association proposed a 2vs2 tournament on Counter Strike: Global Offensive during an evening on January 26, 2019 with a final prize of 200€."])}
    }
  },
  "association": {
    "self": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Association"]), _normalize(["The association"])])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo of the association"])},
    "presentation": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the world of Alpes Esport, an association under the law of 1901 founded by a team of enthusiasts, united by a common vision: to shape the future of French Esport."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With experience gained under the Chartreuse Gaming association's colors since 2017 with Chartreuse Gaming Contest #1, its members can testify to no fewer than 11 physical and online tournaments in the space of 7 years, 450 participants and a cashprize exceeding €6500 / €7500."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since 2015, members have been striving to take part in the eSport boom and establish themselves as key local players in the Alps. To achieve this, the association aims to organize several events a year in the Grenoble metropolis."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propelled by a pool of exceptional skills and (with the conviction that Esport has a greater role to play)/(firmly believing in the transformative power of Esport), the association aims to leave a lasting mark on the world of Esport."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immerse yourself in the constantly evolving world of Alpes Esport, a pioneering association. Let's keep up our efforts, energize our fabulous region and help France shine on the international stage."])}
    },
    "numbers": {
      "tournament": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tournaments organized"])},
      "since-2017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["since 2017"])},
      "up-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to"])},
      "more-than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than"])},
      "players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["players gathered"])},
      "cashprize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of cashprize"])}
    },
    "members": {
      "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The members of the association"])},
      "head-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head office members"])},
      "president": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["President"])},
      "vice-president": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vice President"])},
      "secretary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secretary"])}
    },
    "timeline": {
      "2024": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpine Rivals #1"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June 29, 2024 online on Rocket League."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpine Rivals #2"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September 21, 2024 online on Valorant."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpine Rivals #3"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September 09 - 10, 2024 online on League Of Legends."])}
        }
      },
      "2023": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name change"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Gaming association changes its name to Alpes Esport."])}
        }
      },
      "2022": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change of head office"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On May 22, 2022 to Saint-Martin d'Hères by decision of the extraordinary general assembly."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIFA tournament SOS Jeunes pousses"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July 4, 2022 at the Maison de quartier Paul-Bert."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 6"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 15, 2022 online and October 29, 2022 live in the offices of Grenoble Esport."])}
        }
      },
      "2021": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus Games Week"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 22 to 27 February 2021 on the Discord of the association."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus Games Week: Crous Edition"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From May 17 to May 19, 2021 on the Discord of the association."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 5"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 24, 2021 at the Salle du Révol in Saint Laurent du Pont."])}
        }
      },
      "2020": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salon du Jeu Vidéo of PAJ"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February 1, 2020 at the Salle du Révol in Saint Laurent du Pont."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 4 Online"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 25, 2020 on the Discord of the association."])}
        }
      },
      "2019": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Gaming Winter"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January 26, 2019 on the association's Discord."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salon du Jeu Vidéo of PAJ"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February 2, 2019 at the Saint Joseph de Rivière village hall."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 3"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 26 and 27, 2019 at the Salle du Révol in Saint Laurent du Pont."])}
        }
      },
      "2018": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 2"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 20, 2018 at the Saint Joseph de Rivière village hall."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salon du Jeu Vidéo of PAJ"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January 20, 2018 at the Salle de la Priola in Miribel-les-Echelles."])}
        }
      },
      "2017": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse Esport Contest 1"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 28, 2017 at the Maison des Associations in Saint Laurent du Pont."])}
        }
      },
      "2015": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation of the association"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December 29, 2015 at the Maison des Associations in Saint Laurent du Pont."])}
        }
      }
    }
  },
  "contact": {
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "join-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join us !"])}
  },
  "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved"])},
  "site-map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Map"])},
  "legal-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal information"])},
  "intranet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intranet"])},
  "load-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more"])},
  "read-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
  "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
  "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
  "regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulation"])},
  "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentation"])},
  "ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking"])},
  "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
  "cashprize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashprize"])}
}